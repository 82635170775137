import React from "react";
import styled from "styled-components";
import withLayout from "../../layout";
import {FormattedMessage, injectIntl} from "react-intl";
//COMPONENTS
import DropWrapper from "../../components/UI/DropWrapper";
import SimpleStats from "../../components/UI/SimpleStats";
//GENERIC STYLE IMPORT
import {Heading, Paragraph} from "../../components/UI/Typography";
import DropRtlSvg from "../../images/bkg_drop_rtl.svg";
import DropBig2 from '../../images/case_studies/mantegna/DropBig2.svg';
import RedDrop1 from '../../images/case_studies/mantegna/RedDrop1.svg';
import RedDrop2 from '../../images/case_studies/mantegna/RedDrop2.svg';
import HeroCaseStudy from "../../components/HeroCaseStudy";
import LottieController from "../../components/UI/LottieController";
import Graph1 from '../../images/case_studies/mantegna/Grafico1.json';
import Graph2 from '../../images/case_studies/mantegna/Grafico2.json';
import {useInView} from "react-intersection-observer";
import DropBg from "../../components/UI/DropBg";
import useDimensions from 'react-use-dimensions';
import VideoMantegna from '../../images/case_studies/mantegna/banner_mantegna.mp4';
import SEO from "../../components/SEO";
import {Fade} from 'react-reveal';
import Image from "../../components/UI/Image";
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage";
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory";
import SectionTitle from "../../components/CaseStudy/SectionTitle";
import Results from "../../components/CaseStudy/Results";
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"

const StatSection = styled.div`
padding-top:32px;
h1{
font-weight: bold;
font-size: 80px;
letter-spacing: 2.9px;
color:#1D0F41;
}
h2{
font-weight: bold;
font-size: 50px;
letter-spacing: 1.82px;
color:#AA111C;
}
p{
    font-weight:bold;
    font-size:18px
    letter-spacing:0.39px;
    color:#AA111C;
}
`


const StatsDiv = styled.div`
margin-top: 32px;
h3{
color:#1D0F41;
font-weight:700;
font-style:normal;
font-size:32px;
margin:0px;
}
p{
    color:#AA111C;
    font-size:18px;
    font-weight:400;
}

`

const Percentages = styled.div`
color:#1D0F41;
font-weight:600;
font-family:'Proxima-nova';
font-size:min(max(24px,8.0vw),46px);
letter-spacing:1px;
display:flex;
margin-top:56px;
p{
    font-size:24px;
}
div:nth-child(2){
    margin:0px 32px;
}
@media screen and (max-width:768px){
    margin-bottom:32px;
}

`

const CompanyStats = () => {
    return <StatsDiv>
        <h3>
            <FormattedMessage id={'mantegna.oltre'}/> 4000 <FormattedMessage id={'mantegna.clients'}/>
        </h3>
        <p>
            <FormattedMessage id={'mantegna.stats_1_msg'}/>
        </p>
    </StatsDiv>
}


const MantegnaCaseStudy = ({intl}) => {
    const [ref, refSize] = useDimensions();
    const [graphView1, inViewG1] = useInView();
    const [graphView3, inViewG3] = useInView();
    const keywords = intl.formatMessage({id: 'mantegna.meta_keywords'}).split(',') || [];

    return (
        <>
            <SEO title={intl.formatMessage({id: 'mantegna.meta_title'})}
                 description={intl.formatMessage({id: 'mantegna.meta_description'})} keywords={keywords}
                 lang={intl.locale}/>
            <HeroCaseStudy hasPartner={false} stats={<CompanyStats/>}
                           color={'linear-gradient(23.36deg, #640007 16.59%, #AA111C 87.97%)'}
                           heroImage={'case_studies/mantegna/header-mantegna.png'} intl={intl} title={'mantegna.title'}
                           subtitle1={'mantegna.subtitle'} subtitle2={'mantegna.subtitle_2'}
                           categories={['portfolio.filters.design_dev', 'portfolio.filters.creative_communication', 'portfolio.filters.marketing_strategy']}/>
            <div className="section-inner-padding">
                <DropWrapper svgfile={DropRtlSvg} position="left" top="140%"/>
                <DropBg src={RedDrop1} bottom={'10%'} left={'23%'} className="d-none d-lg-block" zIndex={'-1'}/>
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'01'} text={'mantegna.overview_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className={"col-md-5 col-12"}>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"mantegna.client_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"mantegna.client_1"}/>
                                </Paragraph>
                                <StatSection>
                                    <h1>
                                        26,80
                                    </h1>
                                    <h2>
                                        <FormattedMessage id={"mantegna.clients_sub_1"}/>
                                    </h2>
                                    <p>
                                        <FormattedMessage id={"mantegna.clients_sub_2"}/>
                                    </p>
                                </StatSection>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className={"col-md-6 col-12 offset-md-1"}>
                                <Heading level={"4"} className="pt-2">
                                    <FormattedMessage id={"mantegna.client_needs"}/>
                                </Heading>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"mantegna.client_needs_1"}/>
                                </Paragraph>
                                <Heading level={'4'} margin={'58px 0px 24px 0px'}>
                                    <FormattedMessage id={'mantegna.partners'}/>
                                </Heading>
                                <Image
                                    filename={refSize?.width > 768 ? 'case_studies/mantegna/loghi partner mantegna.jpg' : 'case_studies/mantegna/loghi partner-mobile.jpg'}
                                    alt={'loghi partner'}/>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <FullWidthImage
                dangerouslySetInnerHTML={{
                    __html: `
                        <video
                            style="width:100%;height:100%;object-fit:cover"
                            loop
                            muted
                            autoplay
                            playsinline
                            webkit-playsinlines
                            src="${VideoMantegna}"
                        />`
                }}
                imageWidth={1920}
                imageHeight={1080}
            />
            <div className="section-padding">
                <DropBg src={DropBig2} right={'-10%'} zIndex={'-1'} top={'15%'} className="d-none d-lg-block"/>
                <DropBg src={RedDrop2} top={'10%'} right={'10%'} className="d-none d-lg-block"/>
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 d-none d-md-block pl-5">
                                <SectionTitle number={'02'} text={'mantegna.brief_title'}/>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <Heading level={"2"} margin={'0px'}>
                                    <FormattedMessage id={"mantegna.service_big_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"mantegna.services_txt_1"}/>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className="col-md-7 col-sm-12">
                                <SectionTitle number={'03'} text={'mantegna.workflow_title'}/>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"mantegna.solutions_big_title"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"mantegna.solutions_txt_1"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-md-4 col-sm-12 offset-0 offset-md-1 position-relative">
                                <Image filename={'case_studies/mantegna/laptop.jpg'} alt={'laptop'}
                                       className={'case-study-right-pic'}/>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="section-padding">
                <div className="container">
                    <div className="row ">
                        <Fade bottom delay={400}>
                            <div className="col-lg-6 col-sm-12 order-last order-md-first position-relative">
                                <Image filename={'case_studies/mantegna/comunicazione.jpg'} imgClassName="section-image" alt={'communication'}/>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-lg-6  col-12  col-sm-12 order-first order-md-last">
                                <Heading level={"4"}>
                                    <FormattedMessage id={"mantegna.solutions_title_3"}/>
                                </Heading>
                                <Paragraph className="pt-2 pr-md-4">
                                    <FormattedMessage id={"mantegna.solutions_txt_5"}/>
                                </Paragraph>
                                <Paragraph className="pt-2 pr-md-5">
                                    <FormattedMessage id={"mantegna.solutions_txt_6"}/>
                                </Paragraph>
                                <Percentages>
                                    <div>29,82%<br/><p>OR</p></div>
                                    <div>3,13%<p>CR</p></div>
                                    <div>8,72%<br/><p>CTOR</p></div>
                                </Percentages>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div id="04" className="section-padding-bottom">
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-12">
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"mantegna.solutions_paragraph_1"}/>
                                </Paragraph>
                            </div>
                            <div className="col-lg-6  col-md-12 col-12  align-items-center justify-content-center">
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"mantegna.solutions_paragraph_2"}/>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <Results
                subtitle="mantegna.dark_section_paragraph"
                testimonialQuote="mantegna.dark_section_quote"
                testimonialImg="pasquale_gallo.jpg"
                testimonialName="mantegna.dark_section_testimonial_name"
                testimonialRole="mantegna.dark_section_testimonial_desc"
            >
                <div className="col-12 col-lg-3">
                    <div ref={graphView1}>
                        <LottieController
                            lottieJSON={Graph1}
                            inView={inViewG1}
                            width="200px"
                            loop={false}
                            height="100px"
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG1}
                            valueFrom={0}
                            valueTo={16}
                            headingStyle={{fontWeight: 500}}
                            measureUnit="%"
                            label="mantegna.stats_1"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView3}>
                        <LottieController
                            lottieJSON={Graph2}
                            inView={inViewG3}
                            width="200px"
                            height="100px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG3}
                            valueFrom={0}
                            valueTo={200}
                            measureUnit="%"
                            prefix="+"
                            label="mantegna.stats_2"
                            headingLevel="1"
                            labelColor="white"
                            headingStyle={{fontWeight: 500}}
                        />
                    </div>
                </div>
            </Results>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="mantegna.next_success_case_title"
                desc="mantegna.next_success_case_description"
                link="/case-study/enel/"
                intl={intl}
            />
        </>
    );
};

const customProps = {
    localeKey: "mantegna", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(MantegnaCaseStudy));
