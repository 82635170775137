import styled from "styled-components";
// styled components
// Drop Background Styled Component
// svgfile of the drop: ltr || rtl || ltrt || ltrb
// x position of the drop: left || right or %
// y position of the drop: % from top (ex -40%)

const DropWrapper = styled.div`
    background: url(${(props) => props.svgfile}) ${(props) => props.position} ${(props) => props.top} no-repeat;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    @media screen and (max-width: 768px) {
      display:${props => props.hideOnMobile ? 'none!important' : 'unset'}
    }
`;

export default DropWrapper;
